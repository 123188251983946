export const apiUrl = (newPath) => {
  return process.env.NODE_ENV === 'local' ? 'http://localhost:8081' : '' + newPath;
};

export const DropDownStyle = {
  option: (provided, { isDisabled, isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isDisabled ? null : isFocused ? '#4fb973' : null,
    color: isFocused ? 'white' : 'black'
  }),
  control: (provided) => ({
    ...provided,
    'boxShadow': 'inset 0 1px 1px 0 rgba(0, 0, 0, 0.16)',
    'borderColor': '#b9bcca',
    'fontSize': '14px',
    ':hover': {
      borderColor: '#4fb973'
    },
    ':active': {
      borderColor: '#4fb973'
    }
  })
};

export const roles = [
  { label: 'Medical', value: '0' },
  { label: 'Dental', value: '1' },
  { label: 'Veterinary', value: '2' },
  { label: 'Medical Laboratory', value: '3' }
];

export const PRACTICE_TYPE = [
  { label: 'Medical', value: 'medical' },
  { label: 'Dental', value: 'dental' },
  { label: 'Veterinary', value: 'veterinary' },
  { label: 'Medical Laboratory', value: 'medical_laboratory' }
];

export const tasks_behavior_tasks = [
  { label: 'Document Upload', value: 'task_with_doc_upload' },
  { label: 'Mark Complete', value: 'task_mark_complete' },
  { label: 'Form', value: 'task_with_form' },
  { label: 'Task with Certification', value: 'task_with_certification' },
]

export const PRODUCT_TYPES = [
  { label: 'OSHA', value: 'OSHA' },
  { label: 'HIPAA', value: 'HIPAA' }
]

export const FREQUENCY = [
  { value: '0', label: 'Weekly' },
  { value: '1', label: 'Monthly' },
  { value: '2', label: 'Quarterly' },
  { value: '3', label: 'Yearly' },
  { value: '99', label: 'Once' }
];

export const LOCATION_OPTIONS = [
  { id: 'onSiteLasers', name: 'On-Site Lasers' },
  { id: 'onSiteHivTesting', name: 'On-Site HIV Testing' },
  { id: 'onSiteLab', name: 'On-Site Lab' },
  { id: 'labIsCliaWaived', name: 'Lab is CLIA-Waived' },
  { id: 'onSiteTbTesting', name: 'On-Site TB Testing' },
  { id: 'onSiteTbTreatment', name: 'On-Site TB Treatment' },
  { id: 'TenOrMoreEmployees', name: '10+ Employees' },
  { id: 'autoclave', name: 'Autoclave' }
];

export const TYPE_OPTIONS = [
  { value: "simple_forms", label: "Simple Forms" },
  { value: "forms_associated_with_task", label: "Associated with Task" },
  { value: "form_associated_with_library_step", label: "Associated with Library step" }
];

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export const pageSizeOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
];