import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import Tasks from '../components/Masters/Tasks';
import Roles from '../components/Masters/Roles';
import Equipments from '../components/Masters/Equipments';
import Defaultoptions from '../components/Masters/Defaultoptions';
import DefaultLocationType from '../components/Masters/DefaultLocationType';
import Spinner from '../components/Spinner';
import styled from 'styled-components';
import { DropDownStyle } from '../helpers';
import Forms from '../components/Masters/Forms';
import Events from '../components/Masters/Events';
import SurveyTasks from '../components/Masters/surveyTasks';
import Library from '../components/Masters/Library';

const dropDownOptions = [
    { value: 'tasks', label: 'Tasks' },
    { value: 'equipments', label: 'Equipments' },
    { value: 'roles', label: 'Roles' },
    { value: 'defaultoptions', label: 'Default options' },
    { value: 'defaultLocationType', label: 'Default Location Type' },
    { value: 'forms', label: 'Forms' },
    { value: 'libraries', label: 'Libraries' },
    { value: 'surveyTasks', label: 'Survey Tasks' },
    { value: 'events', label: 'Events' }
];
const MasterContainer = styled.div`
        width:90%;
        margin-top:1.5%;,
        display:flex;
        justify-content: space-around;
        align-self: flex-start;
        align-items: flex-start;
        `;
class Master extends Component {
    state = {
        currentPage: 'tasks'
    };
    componentDidMount = () => {
    };
    onDropDownChange = (e) => {
        this.setState({
            currentPage: e.value
        });
    };

    render() {
        const { currentPage } = this.state;
        const { loading } = this.props;
        return (
            <MasterContainer>
                {loading && <Spinner />}
                <Select styles={DropDownStyle} defaultValue={dropDownOptions[0]} onChange={this.onDropDownChange} options={dropDownOptions} />
                {currentPage === 'tasks' &&
                    <Tasks />
                }
                {currentPage === 'forms' &&
                    <Forms />
                }
                {currentPage === 'events' &&
                    <Events />
                }
                {currentPage === 'surveyTasks' &&
                    <SurveyTasks />
                }
                {currentPage === 'libraries' &&
                    <Library />
                }
                {currentPage === 'equipments' &&
                    <Equipments />
                }
                {currentPage === 'roles' &&
                    <Roles />
                }
                {currentPage === 'defaultoptions' &&
                    <Defaultoptions />
                }
                {currentPage === 'defaultLocationType' &&
                    <DefaultLocationType />
                }
            </MasterContainer>
        )
    }
};
const mapStateToProps = (state) => ({
    loading: state.master.loading
});
export default connect(mapStateToProps)(Master);