import axios from 'axios'
import actions from './actionTypes';
import { apiUrl } from '../helpers';
import { toast } from 'react-toastify';


const API_URL = '/api/v1/survey-js/';

const getTasksSuccess = (tasks) => (dispatch) => {
    dispatch({
        type: actions.GET_TASKS_SUCCESS,
        tasks
    });
};
const getTasksError = () => (dispatch) => {
    dispatch({
        type: actions.GET_TASKS_ERROR
    });
};

const getFormsSuccess = (forms) => (dispatch) => {
    dispatch({
        type: actions.GET_FORMS_SUCCESS,
        forms
    });
};
const getFormsSectionSuccess = (sections) => (dispatch) => {
    dispatch({
        type: actions.GET_SECTIONS_SUCCESS,
        sections
    });
};
const getFormsSectionError = () => (dispatch) => {
    dispatch({
        type: actions.GET_SECTIONS_ERROR
    });
};
const getFormsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_FORMS_ERROR
    });
};

const saveFormsError = () => (dispatch) => {
    dispatch({
        type: actions.SAVE_FORMS_ERROR
    });
};

const saveFormSuccess = (form) => (dispatch) => {
    dispatch({
        type: actions.SAVE_FORM_SUCCESS,
        form
    });
};

const getEventsSuccess = (events) => (dispatch) => {
    dispatch({
        type: actions.GET_EVENTS_SUCCESS,
        events
    });
};
const getEventsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_EVENTS_ERROR
    });
};

const saveEventsError = () => (dispatch) => {
    dispatch({
        type: actions.SAVE_FORMS_ERROR
    });
};

const saveEventSucess = (event) => (dispatch) => {
    dispatch({
        type: actions.SAVE_EVENTS_SUCCESS,
        event
    });
};

const getLibrariesSuccess = (libraries) => (dispatch) => {
    dispatch({
        type: actions.GET_LIBRARIES_SUCCESS,
        libraries
    });
};

const getLibrariesError = () => (dispatch) => {
    dispatch({
        type: actions.GET_LIBRARIES_ERROR
    });
};

const saveLibrariesError = () => (dispatch) => {
    dispatch({
        type: actions.SAVE_LIBRARIES_ERROR
    });
};

const saveLibrarySucess = (library) => (dispatch) => {
    dispatch({
        type: actions.SAVE_LIBRARIES_SUCCESS,
        library
    });
};

const getSurveyTasksSuccess = (surveyTasks) => (dispatch) => {
    dispatch({
        type: actions.GET_SURVEY_TASKS_SUCCESS,
        surveyTasks
    });
};

const getSurveyTaskError = () => (dispatch) => {
    dispatch({
        type: actions.GET_SURVEY_TASK_ERROR
    });
};

const saveSurveyTaskError = () => (dispatch) => {
    dispatch({
        type: actions.SAVE_SURVEY_TASK_ERROR
    });
};

const saveSurveyTaskSucess = (surveyTask) => (dispatch) => {
    dispatch({
        type: actions.SAVE_SURVEY_TASK_SUCCESS,
        surveyTask
    });
};

const saveTasksError = () => (dispatch) => {
    dispatch({
        type: actions.SAVE_TASKS_ERROR
    });
};
const getEquipmentsSuccess = (equipments) => (dispatch) => {
    dispatch({
        type: actions.GET_EQUIPMENTS_SUCCESS,
        equipments
    });
};
const getEquipmentsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_EQUIPMENTS_ERROR
    });
};
const getRolesSuccess = (roles) => (dispatch) => {
    dispatch({
        type: actions.GET_ROLES_SUCCESS,
        roles
    });
};
const getRolesError = () => (dispatch) => {
    dispatch({
        type: actions.GET_ROLES_ERROR
    });
};
const getDefaultoptionsSuccess = (defaultoptions) => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULTOPTIONS_SUCCESS,
        defaultoptions
    });
};
const getDefaultLocationTypeError = () => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULTOPTIONS_ERROR
    });
};
const getDefaultLocationTypeSuccess = (defaultLocationType) => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULT_LOCATION_TYPE_SUCCESS,
        defaultLocationType
    });
};
const getDefaultoptionsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULT_LOCATION_TYPE_ERROR
    });
};
const masterRequest = () => (dispatch) => {
    dispatch({
        type: actions.MASTER_REQUEST
    });
};
export const getAllTasks = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order, } = params;
        let apiEndPoint = `/api/tasks`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getTasksSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getTasksError());
    }
};
export const updateTask = (task, stateDetails) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/tasks`), { task, stateDetails });
        toast.success('Task data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in task update.');
        dispatch(saveTasksError());
        return false;
    }
};
export const deleteTask = (task) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/task/delete`), task);
        toast.success('Task deleted successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in task update.')
        dispatch(saveTasksError());
        return false;
    }
};
export const saveNewTask = (task) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.post(apiUrl(`/api/tasks`), { task });
        toast.success('Task save successfully.')
        return payload;
    } catch (error) {
        toast.error('Error in task saving.');
        dispatch(saveTasksError());
        return false;
    }
}

export const getApprovedForms = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `${API_URL}forms/getApprovedForms`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        apiEndPoint += '&is_survey_js=true';
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getFormsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getFormsError());
    }
};

export const getFormsSections = () => async (dispatch) => {
    try {
        dispatch(masterRequest());
        let apiEndPoint = `${API_URL}forms/getFormSections`;
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getFormsSectionSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getFormsSectionError());
    }
};

export const getAllEvents = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order, } = params;
        let apiEndPoint = `${API_URL}events/getEvents`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        apiEndPoint += '&isSurveyJs=true';
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getEventsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getEventsError());
    }
};

export const createEvent = (event) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const url = apiUrl(`${API_URL}events/createEvent`);
        const { data: { payload } } = await axios.post(url, event);
        toast.success('Event added successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Event saving.');
        dispatch(saveEventsError());
        throw error;
    }
}

export const updateEvent = (event) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const url = apiUrl(`${API_URL}events/updateEvent/${event.id}`);
        const { data: { payload } } = await axios.put(url, event);
        toast.success('Event data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Event update.');
        dispatch(saveEventsError());
        return false;
    }
}

export const deleteEvent = (formId) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.delete(apiUrl(`/api/v1/events/${formId}`));
        toast.success('Form deleted successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form update.')
        dispatch(saveEventsError());
        return false;
    }
};

export const deleteLibrary = (formId) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.delete(apiUrl(`/api/v1/library/${formId}`));
        toast.success('Form deleted successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form update.')
        dispatch(saveEventsError());
        return false;
    }
};

export const getAllLibraries = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order, type } = params;
        let apiEndPoint = `/api/v1/survey-js/libraries/activeLibraries`;
        if (pageSize || pageNumber || name || type) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (type) {
            apiEndPoint += '&type=' + type;
        }
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        apiEndPoint += '&isSurveyJs=true';
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getLibrariesSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getLibrariesError());
    }
};

export const getAllSurveyTasks = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order, type } = params;
        let apiEndPoint = `${API_URL}surveyTasks/getSurveyTasks`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getSurveyTasksSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getSurveyTaskError());
    }
};

export const createSurveyTask = (task) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const url = apiUrl(`${API_URL}surveyTasks/createSurveyTask`);
        const { data: { payload } } = await axios.post(url, task);
        toast.success('Task added successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form saving.');
        dispatch(saveSurveyTaskError());
        throw error;
    }
};

export const updateSurveyTask = (task) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const url = apiUrl(`${API_URL}surveyTasks/updateSurveyTask/${task.id}`);
        const { data: { payload } } = await axios.put(url, task);
        toast.success('Task data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in task update.');
        dispatch(saveSurveyTaskError());
        return false;
    }
}

export const deleteSurveyTask = (formId) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.delete(apiUrl(`/api/v1/surveytasks/${formId}`));
        toast.success('Form deleted successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form update.')
        dispatch(saveSurveyTaskError());
        return false;
    }
};

export const getSurveyForm = async (formId) => {
    try {
        const url = apiUrl(`${API_URL}forms/getForm?form_id=${formId}`);
        const { data: { payload } } = await axios.get(url);
        return payload;
    }
    catch (error) {
        return false;
    }
}


export const changeSurveyJson = async (form) => {
    try {
        const url = apiUrl(`${API_URL}forms/changeSurveyJson`);
        const { data: { payload } } = await axios.put(url, form);
        toast.success('Form data updated successfully.');

        return payload;
    } catch (error) {
        toast.error('Error in Form update.');

        return false;
    }
};

export const editMetaData = (form) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const url = apiUrl(`${API_URL}forms/editMetaData`);
        const { data: { payload } } = await axios.put(url, form);
        toast.success('Form data updated successfully.');

        return payload;
    } catch (error) {
        toast.error('Error in Form update.');

        return false;
    }
};

export const deleteForm = (formId) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.delete(apiUrl(`/api/v1/survey-js/forms/${formId}`));
        toast.success('Form deleted successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form update.')
        dispatch(saveFormsError());
        return false;
    }
};

export const approveForm = (form) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`${API_URL}forms/approveForm`), { _id: form._id });
        toast.success('Form approved successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form approval.');
        dispatch(saveFormsError());
        return false;
    }
}

export const createFormVersion = async (form) => {
    try {
        const url = apiUrl(`${API_URL}forms/createFormVersion`);
        const { data: { payload } } = await axios.post(url, form);
        toast.success('Form version created successfully.');

        return payload;
    } catch (error) {
        toast.error('Error in Form version creation.');

        return false;
    }
}

export const createForm = (form) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const url = apiUrl(`${API_URL}forms/createForm`);
        const { data: { payload } } = await axios.post(url, form);
        toast.success('Form added successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Form saving.');
        dispatch(saveFormsError());

        throw error;
    }
};

export const getAllEquipments = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/equipments`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getEquipmentsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getEquipmentsError());
    }
};
export const updateEquipments = (equipments) => async (dispatch) => {
    try {
        const { data: { payload } } = await axios.put(apiUrl(`/api/equipments`), { equipments: equipments });
        toast.success('Equipment data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in equipment update.')
        return false;
    }
};
export const getAllRoles = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/roles`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getRolesSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getRolesError());
    }
};
export const updateRoles = (roles) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/roles`), { roles: roles });
        toast.success('Role data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in role update.')
        return false;
    }
};
export const getAllDefaultoptions = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/defaultoptions`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + encodeURIComponent(name);
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getDefaultoptionsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getDefaultoptionsError());
    }
};
export const updateDefaultoptions = (defaultOption) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/defaultoptions`), { defaultOption: defaultOption });
        toast.success('Default option updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Default option update.')
        return false;
    }
};
export const getAllDefaultLocationType = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/defaultLocationType`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + encodeURIComponent(name);
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getDefaultLocationTypeSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getDefaultLocationTypeError());
    }
};
export const updateDefaultLocationType = (defaultLocationType) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/defaultLocationType`), { defaultLocationType: defaultLocationType });
        toast.success('Default location type updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Default location type update.')
        return false;
    }
};