import React, { memo } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/survey-core.min.css";

const SurveyComponent = memo(({ data, onComplete }) => {
    const { form, record, id } = data || {};
    let survey = new Model(form.json);

    if (record) {
        survey.data = record?.formData;

        if (record.variables) {
            Object.keys(record.variables).forEach((variable) => {
                survey.setVariable(variable, record.variables[variable]);
            });
        }

    }

    survey.onComplete.add((sender, options) => {
        const variable_arr = survey.getVariableNames();
        const variables = {};
        if (variable_arr && variable_arr.length) {
            variable_arr.map((key) => {
                variables[key] = survey.getVariable(key);
            })
        }
        options.variables = variables;
        options.form = form;
        options.id = id;
        onComplete(sender, options);
    });

    return (
        <div>
            <Survey model={survey} />
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        JSON.stringify(prevProps.data) == JSON.stringify(nextProps.data)
    );
});

export default SurveyComponent;