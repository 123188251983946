/**
 * @namespace actionTypes
 * @category Actions
 */

const actionTypes = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
    GET_PRACTICES_SUCCESS: 'GET_PRACTICES_SUCCESS',
    GET_PRACTICES_ERROR: 'GET_PRACTICES_ERROR',
    GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_ERROR: 'GET_LOCATIONS_ERROR',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
    SELECT_PRACTICE: 'SELECT_PRACTICE',
    SELECT_LOCATION: 'SELECT_LOCATION',
    SELECT_USER: 'SELECT_USER',
    LOCATION_PRACTICE_ID: 'LOCATION_PRACTICE_ID',
    USER_PRACTICE_ID: 'USER_PRACTICE_ID',
    GET_RECORDS_SUCCESS: 'GET_RECORDS_SUCCESS',
    GET_RECORDS_ERROR: 'GET_RECORDS_ERROR',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_ERROR: 'GET_ROLES_ERROR',
    GET_EQUIPMENTS_SUCCESS: 'GET_EQUIPMENTS_SUCCESS',
    GET_EQUIPMENTS_ERROR: 'GET_EQUIPMENTS_ERROR',
    GET_TASKS_SUCCESS: 'GET_TASKS_SUCCESS',
    GET_TASKS_ERROR: 'GET_TASKS_ERROR',
    SAVE_TASKS_ERROR: 'SAVE_TASKS_ERROR',

    GET_FORMS_SUCCESS: 'GET_FORMS_SUCCESS',
    GET_FORMS_ERROR: 'GET_FORMS_ERROR',
    GET_SECTIONS_SUCCESS: 'GET_SECTIONS_SUCCESS',
    GET_SECTIONS_ERROR: 'GET_SECTIONS_ERROR',
    SAVE_FORM_SUCCESS: 'SAVE_FORM_SUCCESS',
    SAVE_FORMS_ERROR: 'SAVE_FORMS_ERROR',

    GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
    GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',
    SAVE_EVENTS_SUCCESS: 'SAVE_EVENTS_SUCCESS',
    SAVE_EVENTS_ERROR: 'SAVE_EVENTS_ERROR',

    GET_LIBRARIES_SUCCESS: 'GET_LIBRARIES_SUCCESS',
    GET_LIBRARIES_ERROR: 'GET_LIBRARIES_ERROR',
    SAVE_LIBRARIES_SUCCESS: 'SAVE_LIBRARIES_SUCCESS',
    SAVE_LIBRARIES_ERROR: 'SAVE_LIBRARIES_ERROR',

    GET_SURVEY_TASKS_SUCCESS: 'GET_SURVEY_TASKS_SUCCESS',
    GET_SURVEY_TASK_ERROR: 'GET_SURVEY_TASK_ERROR',
    SAVE_SURVEY_TASK_SUCCESS: 'SAVE_SURVEY_TASK_SUCCESS',
    SAVE_SURVEY_TASK_ERROR: 'SAVE_SURVEY_TASK_ERROR',

    USER_REQUEST: 'USER_REQUEST',
    PRACTICE_REQUEST: 'PRACTICE_REQUEST',
    RECORD_REQUEST: 'RECORD_REQUEST',
    LOCATION_REQUEST: 'LOCATION_REQUEST',
    MASTER_REQUEST: 'MASTER_REQUEST',
    GET_ADMIN_SUCCESS: 'GET_ADMIN_SUCCESS',
    GET_ADMIN_ERROR: 'GET_ADMIN_ERROR',
    ADMIN_REQUEST: 'ADMIN_REQUEST',
    GET_DEFAULTOPTIONS_SUCCESS: 'GET_DEFAULTOPTIONS_SUCCESS',
    GET_DEFAULTOPTIONS_ERROR: 'GET_DEFAULTOPTIONS_ERROR',
    GET_DEFAULT_LOCATION_TYPE_SUCCESS: 'GET_DEFAULT_LOCATION_TYPE_SUCCESS',
    GET_DEFAULT_LOCATION_TYPE_ERROR: 'GET_DEFAULT_LOCATION_TYPE_ERROR',
}
export default actionTypes;
