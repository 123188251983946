import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteLibrary, getAllLibraries } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, Checkbox, ButtonContainer, SearchButton, NoDataFound, PageCountContainer
} from '../';
import { DropDownStyle, pageSizeOptions } from '../../helpers';
import Modal from 'react-bootstrap/Modal';
import warningSvg from '../../assets/images/warning.svg';
import pageEdit from '../../assets/images/pageEdit.svg';
import SurveyCreatorRenderComponent from '../SurveyCreatorRenderComponent';
import { Button } from 'react-bootstrap';
import { Input } from '../Input'


class Libraries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            libraries: [],
            pageNumber: 0,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0,
            sortBy: '_id',
            order: 'desc',
            libraryForEdit: {},
            edit: false,
            addNew: false,
            isUpdateWarningModalOpen: false,
            isDeleteWarningModalOpen: false,
            name: '',
            isModalOpen: false,
            libraryData: {
                name: "",
                type: "Library"
            },
            errors: {},
        };
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getAllLibraries({ ...this.state, type: 'HIPAA' }));

    };

    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { libraries, librariesCount } = updatedProps;
        const pageCount = Math.ceil(librariesCount / pageSize);
        this.setState({
            libraries: libraries,
            totalCount: librariesCount,
            pageCount: pageCount
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1,
            addNew: false,
            edit: false,
            libraryForEdit: {}
        });
        await dispatch(getAllLibraries({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize, type: 'HIPAA' }));
    };

    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };

    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getAllLibraries({ ...this.state, type: 'HIPAA' }));
    };

    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            addNew: false,
            edit: false,
            pageNumber: 0,
            pageSize: 10,
            libraryForEdit: {},
            sortBy: '_id',
            order: 'desc',
            name: ''
        });
        await dispatch(getAllLibraries({
            pageNumber: 0, pageSize: 10, sortBy: '_id',
            order: 'desc', type: 'HIPAA'
        }));
    };

    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getAllLibraries({ ...this.state, pageNumber: 1, pageSize: value, type: 'HIPAA' }));
    };

    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getAllLibraries({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order, type: 'HIPAA' }));
    };

    handelEditRow = (library) => {
        this.setState({
            edit: true,
            addNew: false,
            libraryForEdit: library
        });
    };

    editMetaData = (library) => {
        this.setState({
            libraryData: library,
            isModalOpen: true,
            isEditMode: true,
        });
    }

    deleteLibrary = async (libraryId) => {
        const { dispatch } = this.props;
        const data = await dispatch(deleteLibrary(libraryId));
        if (data) {
            await dispatch(getAllLibraries(this.state));
        };
    };

    handelModelClose = () => {
        const { libraryForEdit } = this.state;
        this.setState({
            isUpdateWarningModalOpen: false,
            libraryForEdit: { ...libraryForEdit, changeForAll: false },
            isDeleteWarningModalOpen: false
        });
    };

    handelCheckboxChange = ({ target: { checked, name } }) => {
        const { libraryForEdit } = this.state;
        this.setState({
            libraryForEdit: { ...libraryForEdit, changeForAll: checked }
        });
    };

    handelDropDownChange = (name, value) => {
        const { libraryForEdit } = this.state;
        if (name === 'frequency') {
            this.setState({
                libraryForEdit: { ...libraryForEdit, frequency: value.value }
            })
        };
        if (name === 'states') {
            const states = value.map(state => state.value);
            this.setState({
                libraryForEdit: { ...libraryForEdit, states }
            });
        };
    };

    handelInputChange = (value, name) => {
        const { libraryForEdit } = this.state;
        this.setState({
            libraryForEdit: { ...libraryForEdit, [name]: value.trimStart() }
        });
    };

    validateLibrary = () => {
        const { libraryData } = this.state;
        let errors = {};

        Object.keys(libraryData).forEach((key) => {
            if (!libraryData[key]) {
                errors[key] = "This field is required";
            }
        });

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            libraryData: {
                ...prevState.libraryData,
                [name]: name === "index" ? Number(value) : value
            },
            errors: {
                ...prevState.errors,
                [name]: "",
            },
        }));
    };

    convertToId(name) {
        return name
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .split(' ')
            .map((word, index) =>
                index === 0
                    ? word.toLowerCase()
                    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join('');
    }


    showModal = () => {
        this.setState({ isModalOpen: true });
    };

    hideModal = () => {
        this.setState({ isModalOpen: false });
        this.setState({
            libraryData: {
                name: "",
                type: "Library",
            },
            errors: {},
        })
    };

    addNewLibrary = async () => {
        await this.resetFilter();
        this.setState({
            addNew: true,
            edit: false,
            libraryForEdit: {
            },
            isModalOpen: true,
            isEditMode: false,
        });
    };
    render() {
        const { libraries, pageSize, pageCount, pageNumber, sortBy, order, isDeleteWarningModalOpen, isUpdateWarningModalOpen, name, totalCount, libraryForEdit, edit } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        const { isModalOpen, libraryData, errors, isEditMode } = this.state;

        let forms = libraries
        return (
            <PageContainer width='100%'>
                {(edit) ?
                    <SurveyCreatorRenderComponent data={{ form_json: libraryForEdit, forms }} onComplete={(isLoad) => {
                        if (isLoad) {
                            console.log("updated")
                        }
                    }} ></SurveyCreatorRenderComponent>
                    :
                    <>
                        <SubHeader>
                            <FilterContainer>
                                <InputContainer>
                                    <StyledLabel>Libraries</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        value={name}
                                        onChange={(e) => this.handleFilterInputChange(e.target.value)}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <StyledLabel>&ensp;</StyledLabel>
                                    <ButtonContainer>
                                        <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                            <Icon className='fa fa-search'></Icon>
                                        </SearchButton>

                                        <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                            <Icon className='fa fa-undo'></Icon>
                                        </SearchButton>
                                        <SearchButton reset={false} onClick={this.addNewLibrary} width='20%' type='button'>
                                            <Icon className='fa fa-plus'></Icon>
                                        </SearchButton>
                                    </ButtonContainer>
                                </InputContainer>
                            </FilterContainer>
                            <PageCountContainer>
                                <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                            </PageCountContainer>
                            <PageSizeContainer>
                                <StyledLabel>&ensp;</StyledLabel>
                                <div>
                                    <Select
                                        styles={DropDownStyle}
                                        defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                        value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                        options={pageSizeOptions}
                                        onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                                    />
                                </div>
                            </PageSizeContainer>
                        </SubHeader>
                        <TableContainer maxHeight='73vh'>
                            <Table className="table  table-bordered ">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderElement className='w-25' scope="col">Libraries
                                            {sortBy !== 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                            {order === 'asc' && sortBy === 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                            {order === 'desc' && sortBy === 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                        </TableHeaderElement>
                                        <TableHeaderElement className='w-15 text-center' scope="col">Action </TableHeaderElement>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {libraries && libraries.map((library) =>
                                        <TableRow key={library._id}>

                                            <TableDataElement className='w-25'>{
                                                library.name ? library.name : '--'}
                                            </TableDataElement>
                                            <TableDataElement className='w-15 text-center'>
                                                <Icon onClick={() => this.editMetaData(library)} className='fa fa-pencil text-success' title='Edit Meta-Data' />
                                                <strong title='Edit Library' style={{ cursor: "pointer" }} onClick={() => this.handelEditRow(library)}>
                                                    <img src={pageEdit} alt='meta-edit' />
                                                </strong>
                                                <Icon title='Delete Library' onClick={() => this.deleteLibrary(library._id)} className='fa fa-trash text-danger' />
                                            </TableDataElement>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            {
                                (!libraries || !libraries.length) &&
                                <NoDataFound>No Data Found</NoDataFound>
                            }
                        </TableContainer>
                        <PaginationContainer>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={pageCount}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item hide"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link nextAndPrev"
                                nextClassName="page-item"
                                nextLinkClassName="page-link nextAndPrev"
                                activeClassName="active"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                            />
                        </PaginationContainer>
                    </>
                }
                {/* Update libraries modal.  */}
                <Modal
                    show={isUpdateWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Apply for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={libraryForEdit.changeForAll} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateOrSaveLibrary} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
                {/* Delete library modal. */}
                <Modal
                    show={isDeleteWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <div className='mx-4 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <img height='100' width='100' alt="Warning" src={warningSvg} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h2> Are you sure?</h2>
                        </div>
                    </div>
                    <Modal.Body className='pt-0'>
                        <p className='text-center m-0'>Do you really want to delete the library? </p>
                        <p className='text-center m-0'>This process cannot be undone.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-light' onClick={this.handelModelClose}>
                            Cancel
                        </button>
                        <button onClick={this.deleteLibrary} className='btn btn-danger'>Delete</button>
                    </Modal.Footer>
                </Modal>
                {/* ✅ Keep Modal Separate and Always Present */}
                <Modal
                    show={isModalOpen}
                    onHide={this.hideModal}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? "Update Library" : "Add New Library"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {["name"].map((field) => (
                            <div key={field} style={{ marginBottom: 10 }}>
                                <label>{field.replace("_", " ").charAt(0).toUpperCase() + field.replace("_", " ").slice(1)}</label>
                                <Input
                                    placeholder={`Enter ${field.replace("_", " ").charAt(0).toUpperCase() + field.replace("_", " ").slice(1)}`}
                                    name={field}
                                    value={libraryData[field]}
                                    onChange={this.handleChange}
                                />
                                {errors[field] && <p style={{ color: "red", fontSize: "12px" }}>{errors[field]}</p>}
                            </div>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>
                            Cancel
                        </Button>
                        <Button style={{ backgroundColor: "green" }} onClick={this.handleLibrariesubmit}>
                            {isEditMode ? "Update" : "Save"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        )
    }
};

const mapStateToProps = (state) => ({
    state: state,
    libraries: state.master.libraries?.data,
    librariesCount: state.master.libraries?.count,
});

export default connect(mapStateToProps)(Libraries);