import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createEvent, deleteEvent, getAllEvents, getAllSurveyTasks, updateEvent } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, Checkbox, ButtonContainer, SearchButton, NoDataFound, PageCountContainer
} from '../';
import { DropDownStyle, pageSizeOptions } from '../../helpers';
import Modal from 'react-bootstrap/Modal';
import warningSvg from '../../assets/images/warning.svg';
import pageEdit from '../../assets/images/pageEdit.svg';
import SurveyComponent from '../Shared/SurveyComponent';


class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            pageNumber: 0,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0,
            sortBy: '_id',
            order: 'desc',
            eventForEdit: {},
            edit: false,
            addNew: false,
            isUpdateWarningModalOpen: false,
            isDeleteWarningModalOpen: false,
            name: '',
            isModalOpen: false,
            formData: {},
            eventData: {
                name: "",
                description: "",
                states: [],
                locations: [],
                practices: [],
                locations_types: [],
                expressions: [],
                notes: [],
                tasks: []
            },
            errors: {},
        };
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getAllEvents({ ...this.state }));

    };

    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { events, eventsCount } = updatedProps;
        const pageCount = Math.ceil(eventsCount / pageSize);
        this.setState({
            events: events,
            totalCount: eventsCount,
            pageCount: pageCount
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1,
            addNew: false,
            edit: false,
            eventForEdit: {}
        });
        await dispatch(getAllEvents({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };

    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };

    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getAllEvents({ ...this.state }));
    };

    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            addNew: false,
            edit: false,
            pageNumber: 0,
            pageSize: 10,
            eventForEdit: {},
            sortBy: '_id',
            order: 'desc',
            name: ''
        });
        await dispatch(getAllEvents({
            pageNumber: 0, pageSize: 10, sortBy: '_id',
            order: 'desc',
        }));
    };

    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getAllEvents({ ...this.state, pageNumber: 1, pageSize: value }));
    };

    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getAllEvents({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order }));
    };

    handelEditRow = (event) => {
        event.form.edit = true;
        this.setState({
            edit: true,
            addNew: false,
            eventForEdit: event
        });
    };

    editMetaData = (event) => {
        this.setState({
            eventData: event,
            isModalOpen: true,
            isEditMode: true,
        });
    }

    deleteEvent = async (eventId) => {
        const { dispatch } = this.props;
        const data = await dispatch(deleteEvent(eventId));
        if (data) {
            await dispatch(getAllEvents(this.state));
        };
    };

    handelModelClose = () => {
        const { eventForEdit } = this.state;
        this.setState({
            isUpdateWarningModalOpen: false,
            eventForEdit: { ...eventForEdit, changeForAll: false },
            isDeleteWarningModalOpen: false
        });
    };

    handelCheckboxChange = ({ target: { checked, name } }) => {
        const { eventForEdit } = this.state;
        this.setState({
            eventForEdit: { ...eventForEdit, changeForAll: checked }
        });
    };

    handelDropDownChange = (name, value) => {
        const { eventForEdit } = this.state;
        if (name === 'frequency') {
            this.setState({
                eventForEdit: { ...eventForEdit, frequency: value.value }
            })
        };
        if (name === 'states') {
            const states = value.map(state => state.value);
            this.setState({
                eventForEdit: { ...eventForEdit, states }
            });
        };
    };

    handleSurveyComplete = async (event, options) => {
        const { dispatch } = this.props;
        const task = {
            formData: event?.data,
            form: options.form
        };

        if (task.form.edit) {
            task.id = options.id;
            delete task.form.edit;
        }

        const action = task.id ? updateEvent : createEvent;
        const data = await dispatch(action(task));

        if (data) {
            await dispatch(getAllEvents(this.state));
            this.setState({ edit: false, addNew: false });
        }
    };

    addNewevent = async () => {
        await this.resetFilter();
        this.setState({
            addNew: true,
            edit: false,
            eventForEdit: {
            },
            isModalOpen: true,
            isEditMode: false,
        });
    };
    render() {
        const { events, pageSize, pageCount, pageNumber, sortBy, order, isDeleteWarningModalOpen, isUpdateWarningModalOpen, name, totalCount, eventForEdit, addNew, edit } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;

        const filteredForms = events?.filter((form) => form.type === "master_event");

        return (
            <PageContainer width='100%'>
                {(addNew) ? (
                    <div style={{ width: "100%" }}>
                        {filteredForms.map((form) => (
                            <SurveyComponent
                                key={form._id}
                                data={{ form }}
                                themeName="lightgreen"
                                onComplete={this.handleSurveyComplete}
                            />
                        ))}
                    </div>
                ) : (edit) ? (
                    <>
                        <div style={{ width: "100%" }}>
                            <SurveyComponent
                                key={eventForEdit._id}
                                data={{ form: eventForEdit.form, record: eventForEdit, id: eventForEdit._id }}
                                themeName="lightgreen"
                                onComplete={this.handleSurveyComplete}
                            />
                        </div>
                    </>
                ) :
                    <>
                        <SubHeader>
                            <FilterContainer>
                                <InputContainer>
                                    <StyledLabel>Events</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        value={name}
                                        onChange={(e) => this.handleFilterInputChange(e.target.value)}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <StyledLabel>&ensp;</StyledLabel>
                                    <ButtonContainer>
                                        <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                            <Icon className='fa fa-search'></Icon>
                                        </SearchButton>

                                        <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                            <Icon className='fa fa-undo'></Icon>
                                        </SearchButton>
                                        <SearchButton reset={false} onClick={this.addNewevent} width='20%' type='button'>
                                            <Icon className='fa fa-plus'></Icon>
                                        </SearchButton>
                                    </ButtonContainer>
                                </InputContainer>
                            </FilterContainer>
                            <PageCountContainer>
                                <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                            </PageCountContainer>
                            <PageSizeContainer>
                                <StyledLabel>&ensp;</StyledLabel>
                                <div>
                                    <Select
                                        styles={DropDownStyle}
                                        defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                        value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                        options={pageSizeOptions}
                                        onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                                    />
                                </div>
                            </PageSizeContainer>
                        </SubHeader>
                        <TableContainer maxHeight='73vh'>
                            <Table className="table  table-bordered ">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderElement className='w-25' scope="col">Events
                                            {sortBy !== 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                            {order === 'asc' && sortBy === 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                            {order === 'desc' && sortBy === 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                        </TableHeaderElement>
                                        <TableHeaderElement className='w-15 text-center' scope="col">Action </TableHeaderElement>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {events && events.filter((event) => event.type !== "master_event").map((event) =>
                                        <TableRow key={event._id}>

                                            <TableDataElement className='w-25'>{
                                                event.name ? event.name : '--'}
                                            </TableDataElement>
                                            <TableDataElement className='w-15 text-center'>
                                                {/* <Icon onClick={() => this.editMetaData(event)} className='fa fa-pencil text-success' title='Edit Meta-Data' /> */}
                                                <strong title='Edit event' style={{ cursor: "pointer" }} onClick={() => this.handelEditRow(event)}>
                                                    <img src={pageEdit} alt='meta-edit' />
                                                </strong>
                                                {/* <Icon title='Delete event' onClick={() => this.deleteEvent(event._id)} className='fa fa-trash text-danger' /> */}
                                            </TableDataElement>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            {
                                (!events || !events.length) &&
                                <NoDataFound>No Data Found</NoDataFound>
                            }
                        </TableContainer>
                        <PaginationContainer>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={pageCount}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item hide"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link nextAndPrev"
                                nextClassName="page-item"
                                nextLinkClassName="page-link nextAndPrev"
                                activeClassName="active"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                            />
                        </PaginationContainer>
                    </>
                }
                {/* Update events modal.  */}
                <Modal
                    show={isUpdateWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Apply for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={eventForEdit.changeForAll} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateOrSaveevent} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
                {/* Delete event modal. */}
                <Modal
                    show={isDeleteWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <div className='mx-4 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <img height='100' width='100' alt="Warning" src={warningSvg} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h2> Are you sure?</h2>
                        </div>
                    </div>
                    <Modal.Body className='pt-0'>
                        <p className='text-center m-0'>Do you really want to delete the event? </p>
                        <p className='text-center m-0'>This process cannot be undone.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-light' onClick={this.handelModelClose}>
                            Cancel
                        </button>
                        <button onClick={this.deleteEvent} className='btn btn-danger'>Delete</button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    state: state,
    events: state.master.events?.data,
    eventsCount: state.master.events?.count,
});

export default connect(mapStateToProps)(Events);