import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteForm, getApprovedForms, createForm, getFormsSections, getSurveyForm, approveForm, editMetaData } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, Checkbox, ButtonContainer, SearchButton, NoDataFound, PageCountContainer
} from '../';
import { DropDownStyle, TYPE_OPTIONS, pageSizeOptions, FREQUENCY, PRODUCT_TYPES, LOCATION_OPTIONS, PRACTICE_TYPE, states } from '../../helpers';
import Modal from 'react-bootstrap/Modal';
import warningSvg from '../../assets/images/warning.svg';
import pageEdit from '../../assets/images/pageEdit.svg';
import SurveyCreatorRenderComponent from '../SurveyCreatorRenderComponent';
import { Button } from 'react-bootstrap';
import { Input } from '../Input';


class Forms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVersions: [],
            isVersionModalOpen: false,
            forms: [],
            pageNumber: 0,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0,
            sortBy: '_id',
            order: 'desc',
            formForEdit: {},
            edit: false,
            addNew: false,
            isUpdateWarningModalOpen: false,
            isDeleteWarningModalOpen: false,
            name: '',
            isModalOpen: false,
            formData: {
                section_id: "",
                name: "",
                description: "",
                index: undefined,
                type: "",
                appears_dashboard: null,
                product: [],
                practice_type: "",
                states: [],
                // options: {},
                // frequency: ""
            },
            errors: {},
            sections: [],
        };
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getApprovedForms({ ...this.state }));
        dispatch(getFormsSections({ ...this.state }));
    };

    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { forms, formsCount, sections } = updatedProps;
        const pageCount = Math.ceil(formsCount / pageSize);
        this.setState({
            forms: forms,
            sections: sections,
            totalCount: formsCount,
            pageCount: pageCount
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1,
            addNew: false,
            edit: false,
            formForEdit: {}
        });
        await dispatch(getApprovedForms({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };

    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };

    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getApprovedForms({ ...this.state }));
    };

    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            addNew: false,
            edit: false,
            pageNumber: 0,
            pageSize: 10,
            formForEdit: {},
            sortBy: '_id',
            order: 'desc',
            name: ''
        });
        await dispatch(getApprovedForms({
            pageNumber: 0, pageSize: 10, sortBy: '_id',
            order: 'desc'
        }));
    };

    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getApprovedForms({ ...this.state, pageNumber: 1, pageSize: value }));
    };

    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getApprovedForms({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order }));
    };

    handelEditRow = async (form) => {
        const saved_form = await getSurveyForm(form._id);
        if (!saved_form) return;
        this.setState({
            edit: true,
            addNew: false,
            formForEdit: { ...form, ...saved_form }
        });
    };

    createFormVersion = async (form) => {
        const saved_form = await getSurveyForm(form._id);
        if (!saved_form) return;
        saved_form.createFormVersion = true;
        this.setState({
            edit: true,
            addNew: false,
            formForEdit: { ...form, ...saved_form }
        });
    }

    editMetaData = (form) => {
        this.setState({
            formData: {
                ...form,
                appears_dashboard: form.appears_dashboard || null,
                section_id: form.section_id || this.state.formData.section_id,
            },
            isModalOpen: true,
            isEditMode: true,
        });
    }

    approvedForm = (form) => {
        const { dispatch } = this.props;
        const data = dispatch(approveForm(form));
        if (data) {
            dispatch(getApprovedForms(this.state));
        }
    };

    deleteForm = async (formId) => {
        const { dispatch } = this.props;
        const data = await dispatch(deleteForm(formId));
        if (data) {
            await dispatch(getApprovedForms(this.state));
        };
    };

    handelModelClose = () => {
        const { formForEdit } = this.state;
        this.setState({
            isUpdateWarningModalOpen: false,
            formForEdit: { ...formForEdit, changeForAll: false },
            isDeleteWarningModalOpen: false
        });
    };

    handelCheckboxChange = ({ target: { checked, name } }) => {
        const { formForEdit } = this.state;
        this.setState({
            formForEdit: { ...formForEdit, changeForAll: checked }
        });
    };

    handelDropDownChange = (name, value) => {
        const { formForEdit } = this.state;
        if (name === 'frequency') {
            this.setState({
                formForEdit: { ...formForEdit, frequency: value.value }
            })
        };
        if (name === 'states') {
            const states = value.map(state => state.value);
            this.setState({
                formForEdit: { ...formForEdit, states }
            });
        };
    };

    handelInputChange = (value, name) => {
        const { formForEdit } = this.state;
        this.setState({
            formForEdit: { ...formForEdit, [name]: value.trimStart() }
        });
    };

    validateForm = () => {
        const { formData } = this.state;
        let errors = {};

        Object.keys(formData).forEach((key) => {
            if (key !== "section_id" && !formData[key]) {
                errors[key] = "This field is required";
            }
        });
        if (formData.type === "simple_forms" && !formData.section_id) {
            errors.section_id = "Section is required";
        }
        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: name === "index" ? Number(value) : value
            },
            errors: {
                ...prevState.errors,
                [name]: "",
            },
        }));
    };

    handleSelectChange = (field, selectedOption) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [field]: selectedOption.value
            }
        }));
    };

    handleMultiSelectChange = (field, selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [field]: values
            }
        }));
    };

    // handleCheckboxChange = (questionId, value) => {
    //     this.setState(prevState => ({
    //         formData: {
    //             ...prevState.formData,
    //             options: {
    //                 ...prevState.formData.options,
    //                 [questionId]: value
    //             }
    //         }
    //     }));
    // };

    convertToId(name) {
        return name
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .split(' ')
            .map((word, index) =>
                index === 0
                    ? word.toLowerCase()
                    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join('');
    }

    handleFormSubmit = async () => {
        // if (!this.validateForm()) return;
        const { dispatch } = this.props;
        const { formData, isEditMode } = this.state;
        const payload = {
            ...formData, is_survey_js: true, id: this.convertToId(formData.name), title: formData.name,
            appears_dashboard: formData.appears_dashboard, section_id: formData.section_id?.value || formData.section_id,
            product: formData.product, description: formData.description, states: formData.states, practice_type: formData.practice_type?.value || formData.practice_type,
            states: formData.states
        };
        try {
            let data;
            if (isEditMode) {
                data = await dispatch(editMetaData(payload));
            } else {
                data = await dispatch(createForm(payload));
                if (data) {
                    this.handelEditRow(data);
                }
            }
            if (data) {
                await dispatch(getApprovedForms(this.state));
                this.resetFormState();
            }
        } catch (error) {
            console.error("Error in Saving Form:", error);
        }
    };

    resetFormState = () => {
        this.setState({
            formData: {
                section_id: "",
                name: "",
                index: null,
                appears_dashboard: null,
            },
            isModalOpen: false
        });
    };

    handleViewVersions = (form) => {
        this.setState({ selectedVersions: form.forms, isVersionModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isVersionModalOpen: false });
    };

    showModal = () => {
        this.setState({ isModalOpen: true });
    };

    hideModal = () => {
        this.setState({ isModalOpen: false });
        this.setState({
            formData: {
                section_id: "",
                name: "",
                index: null,
                appears_dashboard: null,
            },
            errors: {},
        })
    };

    addNewForm = async () => {
        await this.resetFilter();
        this.setState({
            addNew: true,
            edit: false,
            formForEdit: {
            },
            isModalOpen: true,
            isEditMode: false,
        });
    };
    render() {
        const { forms, pageSize, pageCount, pageNumber, sortBy, order, isDeleteWarningModalOpen, isUpdateWarningModalOpen, name, totalCount, formForEdit, edit, sections, selectedVersions, isVersionModalOpen } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        const { isModalOpen, formData, errors, isEditMode } = this.state;

        return (
            <PageContainer width='100%'>
                {(edit) ?
                    <>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                            <Button
                                variant="success"
                                onClick={() => (window.location.href = "/master")}
                            >
                                Back
                            </Button>
                        </div>
                        <SurveyCreatorRenderComponent form={{ ...formForEdit }} onComplete={(isLoad) => {
                            if (isLoad) {
                                console.log("updated")
                            }
                        }} ></SurveyCreatorRenderComponent>
                    </>
                    :
                    <>
                        <SubHeader>
                            <FilterContainer>
                                <InputContainer>
                                    <StyledLabel>Forms</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        value={name}
                                        onChange={(e) => this.handleFilterInputChange(e.target.value)}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <StyledLabel>&ensp;</StyledLabel>
                                    <ButtonContainer>
                                        <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                            <Icon className='fa fa-search'></Icon>
                                        </SearchButton>

                                        <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                            <Icon className='fa fa-undo'></Icon>
                                        </SearchButton>
                                        <SearchButton reset={false} onClick={this.addNewForm} width='20%' type='button'>
                                            <Icon className='fa fa-plus'></Icon>
                                        </SearchButton>
                                    </ButtonContainer>
                                </InputContainer>
                            </FilterContainer>
                            <PageCountContainer>
                                <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                            </PageCountContainer>
                            <PageSizeContainer>
                                <StyledLabel>&ensp;</StyledLabel>
                                <div>
                                    <Select
                                        styles={DropDownStyle}
                                        defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                        value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                        options={pageSizeOptions}
                                        onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                                    />
                                </div>
                            </PageSizeContainer>
                        </SubHeader>
                        <TableContainer maxHeight='73vh'>
                            <Table className="table table-bordered ">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderElement className='w-25' scope="col">Forms
                                            {sortBy !== 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                            {order === 'asc' && sortBy === 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                            {order === 'desc' && sortBy === 'name' &&
                                                <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                        </TableHeaderElement>
                                        {/* <TableHeaderElement className='w-15 text-center' scope="col">Type </TableHeaderElement> */}
                                        <TableHeaderElement className='w-15 text-center' scope="col">Status </TableHeaderElement>
                                        <TableHeaderElement className='w-15 text-center' scope="col">Action </TableHeaderElement>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {forms && forms.map((form, index) =>
                                        <TableRow key={form._id}>

                                            <TableDataElement className='w-25'>{
                                                form.name ? form.name : '--'}
                                            </TableDataElement>
                                            {/* <TableDataElement className='w-25'>{TYPE_OPTIONS.find(option => option.value === form.type)?.label || '--'}
                                            </TableDataElement> */}
                                            <TableDataElement className='w-15 text-center'>{form.approved ? 'Approved' : 'Draft'}
                                            </TableDataElement>
                                            <TableDataElement className='w-15 text-center'>
                                                <Icon onClick={() => this.editMetaData(form)} className='fa fa-pencil text-success' title='Edit Metadata ' />
                                                <strong title='Edit Form' style={{ cursor: "pointer" }} onClick={() => this.handelEditRow(form)}>
                                                    <img src={pageEdit} alt='meta-edit' />
                                                </strong>
                                                {form.approved && (
                                                    <Icon
                                                        title="Make Version"
                                                        onClick={() => this.createFormVersion(form)}
                                                        className="fa fa-save text-success"
                                                    />
                                                )}
                                                {!form.approved && (<Icon title='Approve Form' onClick={() => this.approvedForm(form)} className='bi bi-check-lg text-success' />)}
                                                {form?.forms?.length > 0 && (
                                                    <span style={{ position: "relative" }}>
                                                        <i
                                                            title="View Versions"
                                                            onClick={() => this.handleViewVersions(form)}
                                                            className="bi bi-three-dots text-success"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </span>
                                                )}
                                            </TableDataElement>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            {
                                (!forms || !forms.length) &&
                                <NoDataFound>No Data Found</NoDataFound>
                            }
                        </TableContainer>
                        <PaginationContainer>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={pageCount}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item hide"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link nextAndPrev"
                                nextClassName="page-item"
                                nextLinkClassName="page-link nextAndPrev"
                                activeClassName="active"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                            />
                        </PaginationContainer>
                    </>
                }
                {/* Update forms modal.  */}
                <Modal
                    show={isUpdateWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Apply for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={formForEdit.changeForAll} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateOrSaveForm} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
                {/* Delete form modal. */}
                <Modal
                    show={isDeleteWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <div className='mx-4 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <img height='100' width='100' alt="Warning" src={warningSvg} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h2> Are you sure?</h2>
                        </div>
                    </div>
                    <Modal.Body className='pt-0'>
                        <p className='text-center m-0'>Do you really want to delete the form? </p>
                        <p className='text-center m-0'>This process cannot be undone.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-light' onClick={this.handelModelClose}>
                            Cancel
                        </button>
                        <button onClick={this.deleteForm} className='btn btn-danger'>Delete</button>
                    </Modal.Footer>
                </Modal>
                {/* ✅ Keep Modal Separate and Always Present */}
                <Modal
                    show={isModalOpen}
                    onHide={this.hideModal}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? "Update Form" : "Add New Form"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "600px", overflowY: "auto" }}>
                        <div style={{ marginBottom: 10 }}>
                            <label>Name</label>
                            <Input
                                placeholder="Enter Name"
                                name="name"
                                value={formData.name}
                                onChange={this.handleChange}
                            />
                            {errors.name && <p style={{ color: "red", fontSize: "12px" }}>{errors.name}</p>}
                        </div>

                        <div style={{ marginBottom: 10 }}>
                            <label>Appears on dashboard</label>
                            <Select
                                placeholder="Select appears on dashboard"
                                value={
                                    formData.appears_dashboard === true
                                        ? { label: "True", value: true }
                                        : formData.appears_dashboard === false
                                            ? { label: "False", value: false }
                                            : null
                                }
                                onChange={(selectedOption) => this.handleSelectChange("appears_dashboard", selectedOption)}
                                options={[
                                    { label: "True", value: true },
                                    { label: "False", value: false }
                                ]}
                            />
                            {errors.appears_dashboard && (
                                <p style={{ color: "red", fontSize: "12px" }}>{errors.appears_dashboard}</p>
                            )}
                        </div>

                        {/* Conditionally Render section_id */}
                        {formData.appears_dashboard === true && (
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    <label>Description</label>
                                    <Input
                                        placeholder="Enter Description"
                                        name="description"
                                        value={formData.description}
                                        onChange={this.handleChange}
                                    />
                                    {errors.description && <p style={{ color: "red", fontSize: "12px" }}>{errors.description}</p>}
                                </div>

                                <div style={{ marginBottom: 10 }}>
                                    <label>Product</label>
                                    <Select
                                        isMulti
                                        placeholder="Select Product"
                                        value={PRODUCT_TYPES.filter(pt => formData?.product?.includes(pt.value))}
                                        onChange={(selectedOptions) => this.handleMultiSelectChange("product", selectedOptions)}
                                        options={PRODUCT_TYPES}
                                    />
                                    {errors.product && (
                                        <p style={{ color: "red", fontSize: "12px" }}>{errors.product}</p>
                                    )}
                                </div>

                                <div style={{ marginBottom: 10 }}>
                                    <label>Category</label>
                                    <Select
                                        placeholder="Select Category"
                                        value={sections.find(section => section._id === formData.section_id)
                                            ? { value: formData.section_id, label: sections.find(section => section._id === formData.section_id).name }
                                            : null}
                                        onChange={(selectedOption) => this.handleSelectChange("section_id", selectedOption)}
                                        disabled={sections.length === 0}
                                        options={sections
                                            .filter(section => formData.product?.some(product => section.type === product))
                                            .map(section => ({
                                                value: section._id,
                                                label: section.name
                                            }))}
                                    />
                                    {errors.section_id && (
                                        <p style={{ color: "red", fontSize: "12px" }}>{errors.section_id}</p>
                                    )}
                                </div>

                                <div style={{ marginBottom: 10 }}>
                                    <label>Index</label>
                                    <Input
                                        placeholder="Enter Index"
                                        type="number"
                                        name="index"
                                        value={formData.index}
                                        onChange={this.handleChange}
                                    />
                                    {errors.index && <p style={{ color: "red", fontSize: "12px" }}>{errors.index}</p>}
                                </div>

                                <div style={{ marginBottom: 10 }}>
                                    <label>States</label>
                                    <Select
                                        isMulti
                                        placeholder="Select States"
                                        value={states.filter(state => formData?.states?.includes(state.value))}
                                        onChange={(selectedOptions) => this.handleMultiSelectChange("states", selectedOptions)}
                                        options={states}
                                    />
                                    {errors.states && (
                                        <p style={{ color: "red", fontSize: "12px" }}>{errors.states}</p>
                                    )}
                                </div>

                                {/* <div style={{ marginBottom: 10 }}>
                                    <label>Frequency</label>
                                    <Select
                                        placeholder="Select Frequency"
                                        value={FREQUENCY.find(option => option.value === formData.frequency) || null}
                                        onChange={(selectedOption) => this.handleSelectChange("frequency", selectedOption)}
                                        options={FREQUENCY}
                                    />
                                    {errors.PRACTICE_TYPE && (
                                        <p style={{ color: "red", fontSize: "12px" }}>{errors.frequency}</p>
                                    )}
                                </div> */}

                                <div style={{ marginBottom: 10 }}>
                                    <label>Practice Type</label>
                                    <Select
                                        placeholder="Select Practice Type"
                                        value={PRACTICE_TYPE.find(option => option.value === formData.practice_type) || null}
                                        onChange={(selectedOption) => this.handleSelectChange("practice_type", selectedOption)}
                                        options={PRACTICE_TYPE}
                                    />
                                    {errors.PRACTICE_TYPE && (
                                        <p style={{ color: "red", fontSize: "12px" }}>{errors.practice_type}</p>
                                    )}
                                </div>

                                {/* <div style={{ marginBottom: 10 }}>
                                    <label style={{ marginBottom: 10 }}>Location Questions</label>
                                    {LOCATION_OPTIONS.map(option => (
                                        <div key={option.id} style={{ marginBottom: 10, display: "flex", flexDirection: "column" }}>
                                            <label>{option.name}</label>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={option.id}
                                                        checked={formData?.options?.[option.id] === true}
                                                        onChange={() => this.handleCheckboxChange(option.id, true)}
                                                    /> Yes
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={option.id}
                                                        checked={formData?.options?.[option.id] === false}
                                                        onChange={() => this.handleCheckboxChange(option.id, false)}
                                                    /> No
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div> */}
                            </div>
                        )}

                        {/* Index Input (Number Field) */}

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideModal}>
                            Cancel
                        </Button>
                        <Button style={{ backgroundColor: "green" }} onClick={this.handleFormSubmit}>
                            {isEditMode ? "Update" : "Save"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Modal Popup */}
                <Modal
                    show={isVersionModalOpen}
                    onHide={this.closeModal}
                    backdrop="static"
                    size='lg'
                    centered
                >
                    <Modal.Header closeButton>
                        <h2>Form Versions</h2>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Form Name</th>
                                    <th>Status</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedVersions.map((version, index) => (
                                    <tr key={version._id}>
                                        <td>{index + 1}</td>
                                        <td>{version?.name}</td>
                                        <td>{version.approved ? 'Approved' : 'Draft'}</td>
                                        <td>{version?.created_by?.fullName}</td>
                                        <td>{new Date(version?.created_at).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            </PageContainer>
        )
    }
};

const mapStateToProps = (state) => ({
    state: state,
    forms: state.master.forms?.data,
    formsCount: state.master.forms?.count,
    sections: state.master.sections,
});

export default connect(mapStateToProps)(Forms);