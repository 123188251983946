import React, { useState, useEffect, useRef } from 'react';
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { setLicenseKey } from "survey-core";
import { changeSurveyJson, createFormVersion } from '../actions/masterAction';
import "survey-core/survey-core.min.css";
import "survey-creator-core/survey-creator-core.min.css";

setLicenseKey(
    "NjI5NzE0YWMtZTdlNS00ZjVjLTljOTQtZTA0YWQwNmFlMjFlOzE9MjAyNi0wMi0xNCwyPTIwMjYtMDItMTQsND0yMDI2LTAyLTE0"
);

const SurveyCreatorRenderComponent = ({ form }) => {
    const creatorRef = useRef(null);
    const [, forceRender] = useState(0);

    const options = {
        haveCommercialLicense: true,
        showPropertyGrid: true,
    };

    useEffect(() => {
        if (!creatorRef.current) {
            const newCreator = new SurveyCreator(options);
            newCreator.text = JSON.stringify(form.json);
            newCreator.saveSurveyFunc = async (no, callback) => {
                try {
                    let savedData;
                    if (form && form.createFormVersion) {
                        delete form.createFormVersion;
                        const versionPayload = {
                            _id: form._id,
                            json: newCreator.JSON,
                            index: form.index,
                            section_id: form.section_id,
                            icon_id: form.icon_id,
                            master_form_id: form.master_form_id,
                        };
                        savedData = await createFormVersion(versionPayload);
                    } else {
                        savedData = await changeSurveyJson({ ...form, json: newCreator.JSON });
                    }
                    if (savedData) {
                        newCreator.text = JSON.stringify(newCreator.JSON);
                        forceRender(n => n + 1);
                    }
                    callback(no, true);
                } catch (error) {
                    console.error('Error in saveSurveyFunc:', error);
                    callback(no, false);
                }
            };

            creatorRef.current = newCreator;
            forceRender(n => n + 1);
        } else if (form.json) {
            creatorRef.current.text = JSON.stringify(form.json);
            forceRender(n => n + 1);
        }
    }, [form]);

    return (
        <div style={{ height: "90vh" }}>
            {creatorRef.current ? <SurveyCreatorComponent creator={creatorRef.current} /> : <p>Loading...</p>}
        </div>
    );
};

export default SurveyCreatorRenderComponent;